import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/collaborateur";

export const loginCollaborateur = (data) => {
  return axiosInstanceCollab.post(`${BASE_URL}` + "/login-collab", data);
};
/* 
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("tokenCollab"); */

export const isLoggedInCollaborateur = () => {
  return axiosInstanceCollab.get(`${BASE_URL}` + "/verifyCollab");
};

export const getCollaborateur = () => {
  return axiosInstanceCollab.get(`${URL}`);
};

export const createCollaborateur = (data) => {
  return axiosInstanceCollab.post(`${URL}`, data);
};

export const switchActiveCollaborateur = (switchData) => {
  const data = {
    isActive: !switchData.value,
  };
  return axiosInstanceCollab.put(
    `${URL}/switch-active/${switchData.id_collab}`,
    data
  );
};

export const updateInfoCollaborateur = (data) => {
  const updateData = {
    fname_collab: data.fname_collab,
    email_collab: data.email_collab,
    tel_collab: data.tel_collab,
  };
  return axiosInstanceCollab.put(`${URL}/info/${data.id_collab}`, updateData);
};

export const updatePwdCollaborateur = (id, data) => {
  return axiosInstanceCollab.put(`${URL}/password/${id}`, data);
};

export const deleteCollaborateur = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};

export const updateSuperAdmin = (id, data) => {
  return axiosInstanceCollab.put(`${BASE_URL}/superAdmin/${id}`, data);
};
