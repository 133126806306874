<template>
  <LayoutVisiteur>
    <LoginCompoAdmin />
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import LoginCompoAdmin from "../../components/admin/LoginAdministrateur.vue";

export default {
  name: "LoginAdmin",
  components: {
    LayoutVisiteur,
    LoginCompoAdmin,
  },
};
</script>
