<template>
  <section class="d-flex flex-lg-row flex-column">
    <div
      class="sub-1 p-4 bg-light text-dark d-flex flex-column align-items-start justify-content-start"
    >
      <h4 class="text-primary">Se connecter</h4>
      <hr class="w-100" />
      <div
        class="container-fluid d-flex align-items-end justify-content-center"
      >
        <img src="../../assets/illustration/Illus-Admin.svg" alt="" srcset="" />
      </div>
      <div class="container-fluid">
        <form @submit.prevent="submitCollab">
          <div class="mb-3">
            <label for="inputMail" class="form-label">Adresse mail</label>
            <input
              type="email"
              class="form-control"
              v-model="form.email_collab"
              autocomplete="off"
              required
              placeholder="nom@mail.com"
            />
          </div>
          <div class="mb-3">
            <label for="inputPassword" class="form-label">Mot de passe</label>
            <input
              type="password"
              class="form-control"
              autocomplete="off"
              v-model="form.pwd_collab"
              required
            />
          </div>
          <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-primary">Se connecter</button>
          </div>
        </form>
      </div>
    </div>
    <div class="sub-2 d-flex align-items-center justify-content-center">
      <img src="../../assets/logo/MK-CO-1-B.svg" alt="" srcset="" />
    </div>
  </section>
</template>

<script>
import { loginCollaborateur } from "../../api/collaborateur";
import { success, error } from "../../utils/toast";

export default {
  name: "LoginAdmin",
  data() {
    return {
      form: {
        email_collab: "",
        pwd_collab: "",
      },
    };
  },
  methods: {
    async submitCollab() {
      await loginCollaborateur(this.form).then((result) => {
        if (result.data.error) {
          error(result.data.error);
        } else {
          localStorage.setItem("tokenCollab", result.data[1].tokenCollab);
          this.$store.dispatch(
            "collaborateurStore/setCollaborateur",
            result.data[0]
          );
          this.$store.dispatch("collaborateurStore/setConnected");
          //this.$router.go("/dashboard"); //go()
          this.$router.push(this.$route.query.redirect || "/admin/dashboard");
          success(`Bienvenue ${result.data[0].fname_collab}`);
        }
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  height: 75vh;
}

.sub-1 {
  flex: 2;

  img {
    width: 40%;
  }

  .link {
    cursor: pointer;
  }
}

.sub-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url("../../assets/img/bg-img-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 4;

  img {
    width: 25%;
  }
}
</style>
